<template>
  <div class="form-group row">
    <label class="col-sm-2 col-form-label">ระบุชื่อลูกค้า</label>
    <div class="col-sm-10">
      <select class="form-control form-control-sm select2 customer-select2"
              :disabled="$route.query.patient_id"
              style="width: 100%;">
        <option value=""></option>
        <option :value="patient.id"
                v-for="patient in patients"
                :key="'patient_'+patient.id">{{ patient.full_name }}</option>
      </select>
    </div>
  </div>

  <div class="row" v-if="!patient">
    <div class="col-sm-12">
      <div class="box">
        <div class="box-body h-300 text-center">
          <i class="text-primary fs-80 fas fa-user mt-55"></i>
          <h4 class="text-muted mt-3">ข้อมูลลูกค้าที่ระบุจะปรากฎที่นี่</h4>
        </div>
      </div>
    </div>
  </div>

  <div class="row" v-else>
    <div class="col-sm-12">
      <div class="row">
        <div class="col-md-4">
          <div class="box h-p100 mb-0">
            <div class="box-header small-box-header">
              <h4 class="mb-0">ข้อมูลลูกค้า</h4>
            </div>
            <div class="box-body text-center p-3">
              <img :src="patient.image ? patient.image : '/images/avatar-girl.png'"
                   onerror="this.src='/images/avatar-girl.png'" class="h-100">

              <table class="table table-borderless patient-details-table text-start mt-3">
                <tr>
                  <td>รหัสลูกค้า</td>
                  <td>: {{ patient.patient_id }}</td>
                </tr>
                <tr>
                  <td>HN</td>
                  <td>: {{ patient.hn }}</td>
                </tr>
                <tr>
                  <td>การใช้งาน</td>
                  <td class="form-group">
                    <div class="c-inputs-stacked">
                      :
                      <input name="group123" type="radio" id="radio_123" value="1" disabled :checked="patient.status === 1">
                      <label for="radio_123" class="me-10">เปิด</label>
                      <input name="group123" type="radio" id="radio_456" value="0" disabled :checked="patient.status === 0">
                      <label for="radio_456" class="me-10">ปิด</label>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div class="col-md-8">
          <div class="box h-p100 mb-0">
            <div class="box-header small-box-header">
              <h4 class="mb-0">ข้อมูลส่วนตัว</h4>
            </div>
            <div class="box-body p-3">
              <table class="table table-borderless table-small">
                <tr>
                  <td width="15%">คำนำหน้า</td>
                  <td>
                    <div class="row">
                      <div class="col-md-4">
                        <input type="text"
                               class="form-control form-control-sm"
                               :value="patient.namePrefix ? patient.namePrefix.prefix : ''" disabled>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td width="15%">ชื่อลูกค้า</td>
                  <td>
                    <div class="row">
                      <div class="col-md-6">
                        <input type="text"
                               class="form-control form-control-sm"
                               :value="patient.first_name" disabled>
                      </div>
                      <div class="col-md-6">
                        <input type="text"
                               class="form-control form-control-sm"
                               :value="patient.last_name" disabled>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td width="15%">ชื่อเล่น</td>
                  <td>
                    <div class="row">
                      <div class="col-md-6">
                        <input type="text"
                               class="form-control form-control-sm"
                               :value="patient.nick_name" disabled>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td width="15%">เพศ</td>
                  <td>
                    <div class="row">
                      <div class="col-md-3">
                        <input type="text"
                               class="form-control form-control-sm"
                               :value="patient.gender" disabled>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td width="15%">วันเกิด</td>
                  <td>
                    <div class="row">
                      <div class="col-md-3">
                        <input type="text"
                               class="form-control form-control-sm"
                               value="23" disabled>
                      </div>
                      <div class="col-md-3">
                        <input type="text"
                               class="form-control form-control-sm"
                               value="มีนาคม" disabled>
                      </div>
                      <div class="col-md-3">
                        <input type="text"
                               class="form-control form-control-sm"
                               value="1993" disabled>
                      </div>
                      <div class="col-md-3">
                        <input type="text"
                               class="form-control form-control-sm"
                               value="อายุ 29 ปี" disabled>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td width="15%">สัญชาติ</td>
                  <td>
                    <div class="row">
                      <div class="col-md-12">
                        <input type="text"
                               :value="patient.nationality"
                               class="form-control form-control-sm" disabled>
                      </div>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-md-12">
          <div class="box">
            <div class="box-header small-box-header">
              <h4 class="mb-0">ข้อมูลอื่น ๆ</h4>
            </div>
            <div class="box-body p-3">
              <div class="row">
                <div class="col-md-6">
                  <table class="table table-borderless table-small">
                    <tr>
                      <td width="40%">อาชีพ</td>
                      <td>
                        <div class="row">
                          <div class="col-md-12">
                            <input type="text"
                                   :value="patient.occupation"
                                   class="form-control form-control-sm" disabled>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td width="40%">อาชีพ (อื่น)</td>
                      <td>
                        <div class="row">
                          <div class="col-md-12">
                            <input type="text"
                                   :value="patient.occupation_other"
                                   class="form-control form-control-sm" disabled>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td width="40%">รหัสประเทศ (มือถือ)</td>
                      <td>
                        <div class="row">
                          <div class="col-md-12">
                            <input type="text"
                                   :value="patient.country ? patient.country.code : ''"
                                   class="form-control form-control-sm" disabled>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td width="40%">เบอร์มือถือ</td>
                      <td>
                        <div class="row">
                          <div class="col-md-12">
                            <input type="text"
                                   :value="patient.mobile"
                                   class="form-control form-control-sm" disabled>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td width="40%">เบอร์มือถือ (สำรอง)</td>
                      <td>
                        <div class="row">
                          <div class="col-md-12">
                            <input type="text"
                                   :value="patient.mobile_alternative"
                                   class="form-control form-control-sm" disabled>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td width="40%">อีเมล</td>
                      <td>
                        <div class="row">
                          <div class="col-md-12">
                            <input type="text"
                                   :value="patient.email"
                                   class="form-control form-control-sm" disabled>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>
                <div class="col-md-6">
                  <table class="table table-borderless table-small">
                    <tr>
                      <td width="30%">หมู่เลือด</td>
                      <td>
                        <div class="row">
                          <div class="col-md-12">
                            <input type="text"
                                   :value="patient.bloodGroup ? patient.bloodGroup.name : ''"
                                   class="form-control form-control-sm" disabled>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td width="30%">โรคประจำตัว</td>
                      <td>
                        <div class="row">
                          <div class="col-md-12">
                            <textarea class="form-control form-control-sm"
                                      v-model="patient.congenital_disease"
                                      disabled />
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td width="30%">การแพ้ยา</td>
                      <td>
                        <div class="row">
                          <div class="col-md-12">
                            <textarea class="form-control form-control-sm"
                                      v-model="patient.drug_allergy"
                                      disabled />
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td width="30%">หมายเหตุ</td>
                      <td>
                        <div class="row">
                          <div class="col-md-12">
                            <textarea class="form-control form-control-sm"
                                      v-model="patient.note"
                                      disabled />
                          </div>
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row" v-if="patient.address">
        <div class="col-md-12">
          <div class="box">
            <div class="box-header small-box-header">
              <h4 class="mb-0">ที่อยู่ปัจจุบัน</h4>
            </div>
            <div class="box-body p-3">
              <table class="table table-borderless table-small">
                <tr>
                  <td width="30%">ประเทศ</td>
                  <td>
                    <div class="row">
                      <div class="col-md-6">
                        <input type="text"
                               class="form-control form-control-sm"
                               :value="patient.address.country ? patient.address.country.name : ''" disabled>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td width="30%">บ้านเลขที่</td>
                  <td>
                    <div class="row">
                      <div class="col-md-3">
                        <input type="text"
                               class="form-control form-control-sm"
                               :value="patient.address.house_no" disabled>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td width="30%">หมู่บ้าน/อพาร์ทเมนท์/คอนโด</td>
                  <td>
                    <div class="row">
                      <div class="col-md-12">
                        <input type="text"
                               class="form-control form-control-sm"
                               :value="patient.address.village" disabled>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td width="30%">หมู่</td>
                  <td>
                    <div class="row">
                      <div class="col-md-6">
                        <input type="text"
                               class="form-control form-control-sm"
                               :value="patient.address.group" disabled>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td width="30%">ซอย</td>
                  <td>
                    <div class="row">
                      <div class="col-md-6">
                        <input type="text"
                               class="form-control form-control-sm"
                               :value="patient.address.alley" disabled>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td width="30%">ถนน</td>
                  <td>
                    <div class="row">
                      <div class="col-md-6">
                        <input type="text"
                               class="form-control form-control-sm"
                               :value="patient.address.road" disabled>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td width="30%">จังหวัด</td>
                  <td>
                    <div class="row">
                      <div class="col-md-6">
                        <input type="text"
                               class="form-control form-control-sm"
                               :value="patient.address.province" disabled>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td width="30%">เขต/อำเภอ</td>
                  <td>
                    <div class="row">
                      <div class="col-md-6">
                        <input type="text"
                               class="form-control form-control-sm"
                               :value="patient.address.district" disabled>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td width="30%">แขวง/ตำบล</td>
                  <td>
                    <div class="row">
                      <div class="col-md-6">
                        <input type="text"
                               class="form-control form-control-sm"
                               :value="patient.address.sub_district" disabled>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td width="30%">รหัสไปรษณีย์</td>
                  <td>
                    <div class="row">
                      <div class="col-md-3">
                        <input type="text"
                               class="form-control form-control-sm"
                               :value="patient.address.zip_code" disabled>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td width="30%">โทรศัพท์ (บ้าน)</td>
                  <td>
                    <div class="row">
                      <div class="col-md-12">
                        <input type="text"
                               class="form-control form-control-sm"
                               :value="patient.address.phone" disabled>
                      </div>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="row" v-for="contact in patient.emergencyContacts"
           :key="'emergency_'+contact.id">
        <div class="col-md-12">
          <div class="box">
            <div class="box-header small-box-header">
              <h4 class="mb-0">ผู้ติดต่อฉุกเฉิน</h4>
            </div>
            <div class="box-body p-3">
              <div class="row">
                <div class="col-md-6">
                  <table class="table table-borderless table-small">
                    <tr>
                      <td width="35%">คำนำหน้า</td>
                      <td>
                        <div class="row">
                          <div class="col-md-12">
                            <input type="text"
                                   class="form-control form-control-sm"
                                   :value="contact.namePrefix ? contact.namePrefix.prefix : ''" disabled>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td width="35%">ชื่อ-สกุล ผู้ติดต่อ</td>
                      <td>
                        <div class="row">
                          <div class="col-md-12">
                            <input type="text"
                                   class="form-control form-control-sm"
                                   :value="contact.name" disabled>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td width="35%">ความสัมพันธ์</td>
                      <td>
                        <div class="row">
                          <div class="col-md-12">
                            <input type="text"
                                   class="form-control form-control-sm"
                                   :value="contact.relationship ? contact.relationship.name : ''" disabled>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td width="35%">ความสัมพันธ์ (อื่น)</td>
                      <td>
                        <div class="row">
                          <div class="col-md-12">
                            <input type="text"
                                   class="form-control form-control-sm"
                                   :value="contact.relationship_other" disabled>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td width="35%">รหัสประเทศ (มือถือ)</td>
                      <td>
                        <div class="row">
                          <div class="col-md-12">
                            <input type="text"
                                   class="form-control form-control-sm"
                                   :value="contact.mobile_country_code" disabled>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td width="35%">เบอร์มือถือ</td>
                      <td>
                        <div class="row">
                          <div class="col-md-12">
                            <input type="text"
                                   class="form-control form-control-sm"
                                   :value="contact.mobile" disabled>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td width="35%">เบอร์มือถือ (สำรอง)</td>
                      <td>
                        <div class="row">
                          <div class="col-md-12">
                            <input type="text"
                                   class="form-control form-control-sm"
                                   :value="contact.mobile_alternative" disabled>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td width="35%">อีเมล</td>
                      <td>
                        <div class="row">
                          <div class="col-md-12">
                            <input type="text"
                                   class="form-control form-control-sm"
                                   :value="contact.email" disabled>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>
                <div class="col-md-6">
                  <table class="table table-borderless table-small">
                    <tr>
                      <td width="35%">ประเทศ</td>
                      <td>
                        <div class="row">
                          <div class="col-md-12">
                            <input type="text"
                                   class="form-control form-control-sm"
                                   :value="contact.country ? contact.country.name : ''" disabled>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td width="35%">บ้านเลขที่</td>
                      <td>
                        <div class="row">
                          <div class="col-md-12">
                            <input type="text"
                                   class="form-control form-control-sm"
                                   :value="contact.house_no" disabled>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td width="35%">หมู่บ้าน/อพาร์ทเมนท์/คอนโด</td>
                      <td>
                        <div class="row">
                          <div class="col-md-12">
                            <input type="text"
                                   class="form-control form-control-sm"
                                   :value="contact.village" disabled>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td width="35%">หมู่</td>
                      <td>
                        <div class="row">
                          <div class="col-md-12">
                            <input type="text"
                                   class="form-control form-control-sm"
                                   :value="contact.group" disabled>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td width="35%">ซอย</td>
                      <td>
                        <div class="row">
                          <div class="col-md-12">
                            <input type="text"
                                   class="form-control form-control-sm"
                                   :value="contact.alley" disabled>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td width="35%">ถนน</td>
                      <td>
                        <div class="row">
                          <div class="col-md-12">
                            <input type="text"
                                   class="form-control form-control-sm"
                                   :value="contact.road" disabled>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td width="35%">จังหวัด</td>
                      <td>
                        <div class="row">
                          <div class="col-md-12">
                            <input type="text"
                                   class="form-control form-control-sm"
                                   :value="contact.province" disabled>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td width="35%">เขต/อำเภอ</td>
                      <td>
                        <div class="row">
                          <div class="col-md-12">
                            <input type="text"
                                   class="form-control form-control-sm"
                                   :value="contact.district" disabled>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td width="35%">แขวง/ตำบล</td>
                      <td>
                        <div class="row">
                          <div class="col-md-12">
                            <input type="text"
                                   class="form-control form-control-sm"
                                   :value="contact.sub_district" disabled>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td width="35%">รหัสไปรษณีย์</td>
                      <td>
                        <div class="row">
                          <div class="col-md-12">
                            <input type="text"
                                   class="form-control form-control-sm"
                                   :value="contact.zip_code" disabled>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td width="35%">โทรศัพท์ (บ้าน)</td>
                      <td>
                        <div class="row">
                          <div class="col-md-12">
                            <input type="text"
                                   class="form-control form-control-sm"
                                   :value="contact.phone" disabled>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['formData', 'errors'],
  data() {
    return {
      patients: [],
      patient: null,
      form: {}
    }
  },
  beforeMount() {
    this.form = this.formData;
  },
  beforeUpdate() {
    this.form = this.formData;
  },
  mounted() {
    this.loadPatients();
  },
  watch: {
    errors: {
      handler(newValue) {
        this.allErrors = newValue;
      },
      deep: true
    },
    formData: {
      handler(newValue) {
        this.$emit('update:formData', newValue);
      },
      deep: true
    },
  },
  methods: {
    initSelect2() {
      let self = this;

      if (['iframe_requests_new', 'case_requests_mapping', 'case_requests_task_mapping'].includes(this.$route.name)) {
        window.$('.customer-select2').select2({
          placeholder: 'โปรดระบุ',
        });
      } else {
        window.$('.customer-select2').select2({
          placeholder: 'โปรดระบุ',
          dropdownParent: window.$('#new-visit-modal')
        });
      }


      window.$('.customer-select2').on('change.select2', function () {
        self.form.selected_patient_id = window.$(this).val();
        self.loadPatientDetails();
      });

      if (this.form.selected_patient_id) {
        window.$('.customer-select2')
          .val(self.form.selected_patient_id)
          .trigger('change');
      }
    },
    loadPatients() {
      this.patients = [];

      this.axios.get('/patients')
        .then(res => {
          this.patients = res.data.data;

          setTimeout(() => {
            this.initSelect2();

            if (this.$route.query.patient_id) {
              window.$('.customer-select2')
                .val(this.$route.query.patient_id)
                .trigger('change');
            }
          }, 500);
        })
    },
    loadPatientDetails() {
      this.patient = null;

      if (this.form.selected_patient_id) {
        this.axios.get('/patients/' + this.form.selected_patient_id)
          .then(res => {
            this.form.currentPatient = res.data.data;
            this.patient = res.data.data;
          })
      }
    }
  }
}
</script>