<template>
  <div class="row" v-if="!form.patient_type">
    <div class="col-6">
      <div class="box h-500">
        <div class="box-body d-flex align-items-center">
          <div class="text-center w-p100" @click="form.patient_type = '1'">
            <i class="fas fa-user fs-100 text-primary"></i>
            <h4 class="mt-3">ลูกค้าเก่า</h4>
          </div>
        </div>
      </div>
    </div>
    <div class="col-6">
      <div class="box h-500">
        <div class="box-body d-flex align-items-center">
          <div class="text-center w-p100" @click="form.patient_type = '2'">
            <i class="fas fa-user-plus fs-100 text-muted"></i>
            <h4 class="mt-3">ลูกค้าใหม่</h4>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row" v-else>
    <div class="col-12">
      <div :class="['box-body p-3', {'max-h-500': !['iframe_requests_new', 'case_requests_mapping'].includes($route.name)}]">
        <existing-user :formData="form" v-if="form.patient_type === '1'" />
        <new-user :formData="form" :errors="allErrors" v-else />
      </div>
    </div>
  </div>
</template>

<script>
import ExistingUser from "@/views/appointment/newVisitModal/steps/1/ExistingUser";
import NewUser from "@/views/appointment/newVisitModal/steps/1/NewUser";
export default {
  props: ['formData', 'errors'],
  components: {ExistingUser, NewUser},
  data() {
    return {
      form: {}
    }
  },
  beforeMount() {
    this.form = this.formData;
  },
  beforeUpdate() {
    this.form = this.formData;
  },
  watch: {
    errors: {
      handler(newValue) {
        this.allErrors = newValue;
      },
      deep: true
    },
    formData: {
      handler(newValue) {
        this.$emit('update:formData', newValue);
      },
      deep: true
    },
  },
}
</script>