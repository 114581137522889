<template>
  <div class="row">
    <div class="col-md-4">
      <div class="box h-p100 mb-0">
        <div class="box-header small-box-header">
          <h4 class="mb-0">ข้อมูลลูกค้า</h4>
        </div>
        <div class="box-body text-center p-3">
          <img :src="form.patient.image ? form.patient.image : '/images/avatar-girl.png'"
               onerror="this.src='/images/avatar-girl.png'"
               class="max-h-100">

          <input class="d-none"
                 type="file"
                 @change="readURL($event.target)"
                 ref="imageInput"
                 accept="image/*">

          <br>

          <button type="button"
                  @click.prevent="chooseImage"
                  class="mt-2 waves-effect waves-light btn btn-outline btn-primary btn-sm">
            <i class="fas fa-images"></i> เปลี่ยนรูปโปรไฟล์
          </button>

          <table class="table table-borderless patient-details-table text-start mt-3">
            <tr>
              <td>การใช้งาน</td>
              <td class="form-group">
                <div class="c-inputs-stacked">
                  :
                  <input name="patient_status"
                         type="radio"
                         v-model="form.patient.status"
                         id="patient_active" value="1">
                  <label for="patient_active" class="me-30">เปิด</label>
                  <input name="patient_status"
                         type="radio"
                         v-model="form.patient.status"
                         id="patient_inactive" value="0">
                  <label for="patient_inactive" class="me-30">ปิด</label>
                </div>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>

    <div class="col-md-8">
      <div class="box h-p100 mb-0">
        <div class="box-header small-box-header">
          <h4 class="mb-0">ข้อมูลส่วนตัว</h4>
        </div>
        <div class="box-body p-3">
          <div class="form-group margin-less-form-group row">
            <label class="col-sm-2 col-form-label">คำนำหน้า <span class="text-danger">*</span></label>
            <div class="col-sm-5">
              <select class="form-select form-select-sm"
                      v-model="form.patient.name_prefix_id">
                <option value="">ระบุ</option>
                <option :value="prefix.id"
                        v-for="prefix in prefixes"
                        :key="'prefix_'+prefix.id">{{ prefix.prefix }}</option>
              </select>
            </div>
          </div>
          <div class="form-group margin-less-form-group row">
            <label class="col-sm-2 col-form-label">ชื่อลูกค้า <span class="text-danger">*</span></label>
            <div class="col-sm-5">
              <input type="text"
                     v-model="form.patient.first_name"
                     placeholder="ชื่อ"
                     class="form-control form-control-sm">
            </div>
            <div class="col-sm-5">
              <input type="text"
                     placeholder="นามสกุล"
                     v-model="form.patient.last_name"
                     class="form-control form-control-sm">
            </div>
          </div>
          <div class="form-group margin-less-form-group row">
            <label class="col-sm-2 col-form-label">ชื่อเล่น</label>
            <div class="col-sm-5">
              <input type="text"
                     v-model="form.patient.nick_name"
                     class="form-control form-control-sm">
            </div>
          </div>
          <div class="form-group margin-less-form-group row">
            <label class="col-sm-2 col-form-label">เพศ</label>
            <div class="col-sm-5">
              <select class="form-select form-select-sm"
                      v-model="form.patient.gender">
                <option value="">Select Option</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
                <option value="Other">Other</option>
              </select>
            </div>
          </div>
          <div class="form-group margin-less-form-group row">
            <label class="col-sm-2 col-form-label">วันเกิด <span class="text-danger">*</span></label>
            <div class="col-sm-5">
              <input type="date"
                     v-model="form.patient.dob"
                     class="form-control form-control-sm">
            </div>
          </div>
          <div class="form-group margin-less-form-group row">
            <label class="col-sm-2 col-form-label">สัญชาติ</label>
            <div class="col-sm-10">
              <input type="text"
                     v-model="form.patient.nationality"
                     class="form-control form-control-sm">
            </div>
          </div>
          <div class="form-group margin-less-form-group row">
            <label class="col-sm-4 col-form-label">หมายเลขบัตรประจำตัวประชาชน</label>
            <div class="col-sm-3">
              <input type="text"
                     v-model="form.patient.id_card_number"
                     class="form-control form-control-sm">
            </div>
          </div>
          <div class="form-group margin-less-form-group row">
            <label class="col-sm-4 col-form-label">หมายเลขหนังสือเดินทาง</label>
            <div class="col-sm-3">
              <input type="text"
                     v-model="form.patient.passport_number"
                     class="form-control form-control-sm">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row mt-3">
    <div class="col-md-12">
      <div class="box">
        <div class="box-header small-box-header">
          <h4 class="mb-0">ข้อมูลอื่น ๆ</h4>
        </div>

        <div class="box-body p-3">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group margin-less-form-group row">
                <label class="col-sm-4 col-form-label">อาชีพ</label>
                <div class="col-sm-8">
                  <input type="text"
                         v-model="form.patient.occupation"
                         class="form-control form-control-sm">
                </div>
              </div>
              <div class="form-group margin-less-form-group row">
                <label class="col-sm-4 col-form-label">อาชีพ (อื่น)</label>
                <div class="col-sm-8">
                  <input type="text"
                         v-model="form.patient.occupation_other"
                         class="form-control form-control-sm">
                </div>
              </div>
              <div class="form-group margin-less-form-group row">
                <label class="col-sm-4 col-form-label">รหัสประเทศ (มือถือ)</label>
                <div class="col-sm-8">
                  <select class="form-select form-select-sm"
                          v-model="form.patient.country_id">
                    <option value="">Choose</option>
                    <option :value="country.id"
                            v-for="country in countries"
                            :key="'country_'+country.id">{{ country.name }}</option>
                  </select>
                </div>
              </div>
              <div class="form-group margin-less-form-group row">
                <label class="col-sm-4 col-form-label">เบอร์มือถือ* <span class="text-danger">*</span></label>
                <div class="col-sm-8">
                  <input type="text"
                         v-model="form.patient.mobile"
                         class="form-control form-control-sm">
                </div>
              </div>
              <div class="form-group margin-less-form-group row">
                <label class="col-sm-4 col-form-label">เบอร์มือถือ (สำรอง)</label>
                <div class="col-sm-8">
                  <input type="text"
                         v-model="form.patient.mobile_alternative"
                         class="form-control form-control-sm">
                </div>
              </div>
              <div class="form-group margin-less-form-group row">
                <label class="col-sm-4 col-form-label">อีเมล</label>
                <div class="col-sm-8">
                  <input type="text"
                         v-model="form.patient.email"
                         class="form-control form-control-sm">
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group margin-less-form-group row">
                <label class="col-sm-4 col-form-label">หมู่เลือด <span class="text-danger">*</span></label>
                <div class="col-sm-4">
                  <select class="form-select form-select-sm"
                          v-model="form.patient.blood_group_id">
                    <option value="">Select Option</option>
                    <option :value="group.id"
                            v-for="group in bloodGroups"
                            :key="'blood_group_'+group.id">{{ group.name }}</option>
                  </select>
                </div>
              </div>
              <div class="form-group margin-less-form-group row">
                <label class="col-sm-4 col-form-label">โรคประจำตัว <span class="text-danger">*</span></label>
                <div class="col-sm-8">
                  <textarea rows="4"
                            class="form-control form-control-sm mb-2"
                            v-model="form.patient.congenital_disease" />
                </div>
              </div>
              <div class="form-group mt-2 margin-less-form-group row">
                <label class="col-sm-4 col-form-label">การแพ้ยา <span class="text-danger">*</span></label>
                <div class="col-sm-8">
                  <textarea rows="4"
                            class="form-control form-control-sm mb-2"
                            v-model="form.patient.drug_allergy" />
                </div>
              </div>
              <div class="form-group mt-2 margin-less-form-group row">
                <label class="col-sm-4 col-form-label">หมายเหตุ</label>
                <div class="col-sm-8">
                  <textarea class="form-control form-control-sm"
                            v-model="form.patient.note"
                            rows="3"></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <div class="box">
        <div class="box-header small-box-header">
          <h4 class="mb-0">ที่อยู่ปัจจุบัน</h4>
        </div>
        <div class="box-body p-3">
          <div class="form-group margin-less-form-group row">
            <label class="col-sm-3 col-form-label">ประเทศ</label>
            <div class="col-sm-4">
              <select class="form-select form-select-sm"
                      v-model="form.patient.address.country_id">
                <option value="">Choose</option>
                <option :value="country.id"
                        v-for="country in countries"
                        :key="'country_'+country.id">{{ country.name }}</option>
              </select>
            </div>
          </div>
          <div class="form-group margin-less-form-group row">
            <label class="col-sm-3 col-form-label">บ้านเลขที่</label>
            <div class="col-sm-4">
              <input type="text"
                     v-model="form.patient.address.house_no"
                     class="form-control form-control-sm">
            </div>
          </div>
          <div class="form-group margin-less-form-group row">
            <label class="col-sm-3 col-form-label">หมู่บ้าน/อพาร์ทเมนท์/คอนโด</label>
            <div class="col-sm-9">
              <input type="text"
                     v-model="form.patient.address.village"
                     class="form-control form-control-sm">
            </div>
          </div>
          <div class="form-group margin-less-form-group row">
            <label class="col-sm-3 col-form-label">หมู่</label>
            <div class="col-sm-4">
              <input type="text"
                     v-model="form.patient.address.group"
                     class="form-control form-control-sm">
            </div>
          </div>
          <div class="form-group margin-less-form-group row">
            <label class="col-sm-3 col-form-label">ซอย</label>
            <div class="col-sm-4">
              <input type="text"
                     v-model="form.patient.address.alley"
                     class="form-control form-control-sm">
            </div>
          </div>
          <div class="form-group margin-less-form-group row">
            <label class="col-sm-3 col-form-label">ถนน</label>
            <div class="col-sm-4">
              <input type="text"
                     v-model="form.patient.address.road"
                     class="form-control form-control-sm">
            </div>
          </div>
          <div class="form-group margin-less-form-group row">
            <label class="col-sm-3 col-form-label">จังหวัด</label>
            <div class="col-sm-4">
              <input type="text"
                     v-model="form.patient.address.province"
                     class="form-control form-control-sm">
            </div>
          </div>
          <div class="form-group margin-less-form-group row">
            <label class="col-sm-3 col-form-label">เขต/อำเภอ</label>
            <div class="col-sm-4">
              <input type="text"
                     v-model="form.patient.address.district"
                     class="form-control form-control-sm">
            </div>
          </div>
          <div class="form-group margin-less-form-group row">
            <label class="col-sm-3 col-form-label">แขวง/ตำบล</label>
            <div class="col-sm-4">
              <input type="text"
                     v-model="form.patient.address.sub_district"
                     class="form-control form-control-sm">
            </div>
          </div>
          <div class="form-group margin-less-form-group row">
            <label class="col-sm-3 col-form-label">รหัสไปรษณีย์</label>
            <div class="col-sm-4">
              <input type="text"
                     v-model="form.patient.address.zip_code"
                     class="form-control form-control-sm">
            </div>
          </div>
          <div class="form-group margin-less-form-group row">
            <label class="col-sm-3 col-form-label">โทรศัพท์ (บ้าน)</label>
            <div class="col-sm-9">
              <input type="text"
                     v-model="form.patient.address.phone"
                     class="form-control form-control-sm">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <div class="box">
        <div class="box-header small-box-header">
          <h4 class="mb-0">ผู้ติดต่อฉุกเฉิน</h4>
        </div>
        <div class="box-body p-3">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group margin-less-form-group row">
                <label class="col-sm-5 col-form-label">คำนำหน้า</label>
                <div class="col-sm-7">
                  <select class="form-select form-select-sm"
                          v-model="form.patient.emergencyContact.name_prefix_id">
                    <option value="">Choose</option>
                    <option :value="prefix.id"
                            v-for="prefix in prefixes"
                            :key="'prefix_'+prefix.id">{{ prefix.prefix }}</option>
                  </select>
                </div>
              </div>
              <div class="form-group margin-less-form-group row">
                <label class="col-sm-5 col-form-label">ชื่อเต็ม</label>
                <div class="col-sm-7">
                  <input type="text"
                         v-model="form.patient.emergencyContact.name"
                         class="form-control form-control-sm"
                         placeholder="ชื่อ">
                </div>
              </div>
              <div class="form-group mt-2 margin-less-form-group row">
                <label class="col-sm-5 col-form-label">ความสัมพันธ์</label>
                <div class="col-sm-7">
                  <select class="form-select form-select-sm"
                          v-model="form.patient.emergencyContact.relationship_id">
                    <option value="">Choose</option>
                    <option :value="relationship.id"
                            v-for="relationship in relationships"
                            :key="'relationship_'+relationship.id">{{ relationship.name }}</option>
                  </select>
                </div>
              </div>
              <div class="form-group margin-less-form-group row">
                <label class="col-sm-5 col-form-label">ความสัมพันธ์ (อื่น)</label>
                <div class="col-sm-7">
                  <input type="text"
                         v-model="form.patient.emergencyContact.relationship_other"
                         class="form-control form-control-sm">
                </div>
              </div>
              <div class="form-group margin-less-form-group row">
                <label class="col-sm-5 col-form-label">รหัสประเทศ (มือถือ)</label>
                <div class="col-sm-7">
                  <input type="text"
                         v-model="form.patient.emergencyContact.mobile_country_code"
                         class="form-control form-control-sm">
                </div>
              </div>
              <div class="form-group margin-less-form-group row">
                <label class="col-sm-5 col-form-label">เบอร์มือถือ</label>
                <div class="col-sm-7">
                  <input type="text"
                         v-model="form.patient.emergencyContact.mobile"
                         class="form-control form-control-sm">
                </div>
              </div>
              <div class="form-group margin-less-form-group row">
                <label class="col-sm-5 col-form-label">เบอร์มือถือ (สำรอง)</label>
                <div class="col-sm-7">
                  <input type="text"
                         v-model="form.patient.emergencyContact.mobile_alternative"
                         class="form-control form-control-sm">
                </div>
              </div>
              <div class="form-group margin-less-form-group row">
                <label class="col-sm-5 col-form-label">อีเมล</label>
                <div class="col-sm-7">
                  <input type="text"
                         v-model="form.patient.emergencyContact.email"
                         class="form-control form-control-sm">
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group margin-less-form-group row">
                <label class="col-sm-5 col-form-label">ประเทศ</label>
                <div class="col-sm-7">
                  <select class="form-select form-select-sm"
                          v-model="form.patient.emergencyContact.country_id">
                    <option value="">Choose</option>
                    <option :value="country.id"
                            v-for="country in countries"
                            :key="'country_'+country.id">{{ country.name }}</option>
                  </select>
                </div>
              </div>
              <div class="form-group margin-less-form-group row">
                <label class="col-sm-5 col-form-label">บ้านเลขที่</label>
                <div class="col-sm-7">
                  <input type="text"
                         v-model="form.patient.emergencyContact.house_no"
                         class="form-control form-control-sm">
                </div>
              </div>
              <div class="form-group margin-less-form-group row">
                <label class="col-sm-5 col-form-label">หมู่บ้าน/อพาร์ทเมนท์/คอนโด</label>
                <div class="col-sm-7">
                  <input type="text"
                         v-model="form.patient.emergencyContact.village"
                         class="form-control form-control-sm">
                </div>
              </div>
              <div class="form-group margin-less-form-group row">
                <label class="col-sm-5 col-form-label">หมู่</label>
                <div class="col-sm-7">
                  <input type="text"
                         v-model="form.patient.emergencyContact.group"
                         class="form-control form-control-sm">
                </div>
              </div>
              <div class="form-group margin-less-form-group row">
                <label class="col-sm-5 col-form-label">ซอย</label>
                <div class="col-sm-7">
                  <input type="text"
                         v-model="form.patient.emergencyContact.alley"
                         class="form-control form-control-sm">
                </div>
              </div>
              <div class="form-group margin-less-form-group row">
                <label class="col-sm-5 col-form-label">ถนน</label>
                <div class="col-sm-7">
                  <input type="text"
                         v-model="form.patient.emergencyContact.road"
                         class="form-control form-control-sm">
                </div>
              </div>
              <div class="form-group margin-less-form-group row">
                <label class="col-sm-5 col-form-label">จังหวัด</label>
                <div class="col-sm-7">
                  <input type="text"
                         v-model="form.patient.emergencyContact.province"
                         class="form-control form-control-sm">
                </div>
              </div>
              <div class="form-group margin-less-form-group row">
                <label class="col-sm-5 col-form-label">เขต/อำเภอ</label>
                <div class="col-sm-7">
                  <input type="text"
                         v-model="form.patient.emergencyContact.district"
                         class="form-control form-control-sm">
                </div>
              </div>
              <div class="form-group margin-less-form-group row">
                <label class="col-sm-5 col-form-label">แขวง/ตำบล</label>
                <div class="col-sm-7">
                  <input type="text"
                         v-model="form.patient.emergencyContact.sub_district"
                         class="form-control form-control-sm">
                </div>
              </div>
              <div class="form-group margin-less-form-group row">
                <label class="col-sm-5 col-form-label">รหัสไปรษณีย์</label>
                <div class="col-sm-7">
                  <input type="text"
                         v-model="form.patient.emergencyContact.zip_code"
                         class="form-control form-control-sm">
                </div>
              </div>
              <div class="form-group margin-less-form-group row">
                <label class="col-sm-5 col-form-label">โทรศัพท์ (บ้าน)</label>
                <div class="col-sm-7">
                  <input type="text"
                         v-model="form.patient.emergencyContact.phone"
                         class="form-control form-control-sm">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['formData', 'errors'],
  data() {
    return {
      form: {},
      prefixes: [],
      countries: [],
      bloodGroups: [],
      relationships: [],
    }
  },
  created() {
    this.loadPrefixes();
    this.loadCountries();
    this.loadBloodGroups();
    this.loadRelationships();
  },
  beforeMount() {
    this.form = this.formData;
  },
  beforeUpdate() {
    this.form = this.formData;
  },
  watch: {
    errors: {
      handler(newValue) {
        this.allErrors = newValue;
      },
      deep: true
    },
    formData: {
      handler(newValue) {
        this.$emit('update:formData', newValue);
      },
      deep: true
    },
  },
  methods: {
    loadPrefixes() {
      this.prefixes = [];

      this.axios.get('/name-prefixes')
        .then(res => {
          this.prefixes = res.data.data;
        })
    },
    loadCountries() {
      this.countries = [];

      this.axios.get('/countries')
        .then(res => {
          this.countries = res.data.data;
        });
    },
    loadBloodGroups() {
      this.bloodGroups = [];

      this.axios.get('/blood-groups')
        .then(res => {
          this.bloodGroups = res.data.data;
        });
    },
    loadRelationships() {
      this.relationships = [];

      this.axios.get('/relationships')
        .then(res => {
          this.relationships = res.data.data;
        });
    },
    chooseImage() {
      this.$refs.imageInput.click();
    },
    readURL(input) {
      let self = this;

      if (input.files && input.files[0]) {
        let reader = new FileReader();

        reader.onload = function (e) {
          self.form.patient.image = e.target.result;
        }

        reader.readAsDataURL(input.files[0]);
      }
    },
  }
}
</script>